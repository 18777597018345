.bx-wrapper .bx-viewport {

	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
    	-ms-transform: translatez(0);
	    transform: translatez(0);
}

.bx-pager,
.bx-wrapper .bx-controls-auto {
    position: absolute;
    bottom: 20px;
}

.bx-wrapper ul li img {
	max-width: 100%;
	width: 100%;
	display: inline-block;
	margin: 0 auto;
}
.baner .bx-wrapper ul li {
	overflow: hidden;
	/*height: 727px;*/
		height: calc(85vh - 87px);
}
.baner .bx-wrapper ul li img {
	position: absolute;
	/*height: 780px;*/
	height: calc(85vh - 87px);
	left: 50%;
	width: 100%;
	max-width: none;
	-webkit-transform: translate(-50%, 0px);
	    -ms-transform: translate(-50%, 0px);
	        transform: translate(-50%, 0px);
}
.bx-wrapper {
	position: relative;
	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
}
.bx-wrapper .bx-pager {
	position: absolute;
	height: 40px;
    padding: 28px 0 0 0;
    right: 0;
    left: 0;
}
.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}
.bx-wrapper .bx-prev, .bx-wrapper .bx-next{
	position: absolute;
    box-sizing: border-box;
    width: 40px;
    height: 85px;
    top: 50%;
    z-index: 1;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    display: inline-block;
    color: rgba(50,50,50,0.4);
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    text-indent: 0;
    margin-top: 16px;
    background-image: url(../img/ico.png);
    background-repeat: no-repeat;
    transition: 0s ease;
    background-position: -11px -122px;
}
.bx-wrapper .bx-next {
    right: 0px;
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
    -ms-transform: rotate(180deg)translate(0, 50%);
    -webkit-transform: rotate(180deg)translate(0, 50%);
    transform: rotate(180deg)translate(0, 50%);
}
.advance .bx-wrapper .bx-prev {
    left: -40px;
    background-position: -13px -119px;
}
.advance .bx-wrapper .bx-next {
    right: -40px;
}
.services .bx-pager {
    bottom: -30px;
}
.services .bx-pager-item a{
	background: #fff;
	text-indent: -9999px;
	display: block;
	width: 12px;
	height: 12px;
	margin: 0 5px;
	outline: 0;
	border-radius: 50%;
	border: 1px solid rgb(28, 50, 84);
	background: transparent;

}
.baner .bx-pager-item a{
	text-indent: -9999px;
	display: block;
	width: 15px;
	height: 15px;
	margin: 0 5px;
	outline: 0;
	border-radius: 50%;
	border: 1px solid #fff;
	background: transparent;

}
.baner .bx-pager-item a:hover, .services .bx-pager-item a:hover,
.baner .bx-pager-item a.active, .services .bx-pager-item a.active {
    border: 1px solid rgb(28, 50, 84);
	background: rgb(28, 50, 84);
}
.baner .bx-wrapper .bx-pager {
    padding: 15px 0 0 0;
}
.promo .bx-wrapper ul li img {
    width: auto;
    height: 100%;
    max-width: none;
    -webkit-transform: translate(-50%, 0px);
            -ms-transform: translate(-50%, 0px);
        transform: translate(-50%, 0px);
    left: 50%;
    position: relative;
}
.promo .bx-controls-direction, .tours-online .bx-controls-direction{
	width: 976px;
    position: absolute;
    top: 173px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
}
.promo  .bx-wrapper .bx-prev, .promo  .bx-wrapper .bx-next, .tours-online  .bx-wrapper .bx-prev, .tours-online  .bx-wrapper .bx-next{
	border:2px solid #fff;
	border-radius:50%;
}
.promo  .bx-wrapper .bx-prev, .tours-online  .bx-wrapper .bx-prev{
	left:-21px;
	background-position: -7px -147px;
}
.promo .bx-wrapper .bx-next, .tours-online  .bx-wrapper .bx-next{
	right:-17px;
	background-position: -8px -183px;
}
.promo .bx-wrapper .bx-next:hover, .tours-online .bx-wrapper .bx-next:hover{
	background-position: -9px -224px;
	background-color:#f2f2f2;
	border-color:#f2f2f2;
}
.promo  .bx-wrapper .bx-prev:hover, .tours-online  .bx-wrapper .bx-prev:hover{
	background-position: -11px -259px;
	background-color:#f2f2f2;
	border-color:#f2f2f2;
}
/** RESET AND LAYOUT
===================================*/
@media screen and (max-width: 1030px){
	.promo .bx-wrapper .bx-next, .tours-online .bx-wrapper .bx-next {
		right: 25px;
	}
	.promo .bx-wrapper .bx-prev, .tours-online .bx-wrapper .bx-prev{
		left:25px;
	}
	.promo .bx-controls-direction, .tours-online .bx-controls-direction{
		width: 100%;
	}
}
@media screen and (max-width:640px){
	.promo .bx-wrapper .bx-next, .tours-online .bx-wrapper .bx-next {
		display:none;
	}
	.promo .bx-wrapper .bx-prev, .tours-online .bx-wrapper .bx-prev{
		display:none;
	}
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(../img/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}
.media .bx-wrapper .bx-next {
	right: 0;
}

.media .bx-wrapper .bx-prev {
	left: 0px;
}
/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: 14px;
	font-family: Arial;
	font-weight: bold;
	color: #666;
}

.bx-wrapper  .bx-pager-item,
.bx-wrapper  .bx-controls-auto-item {
	display: inline-block;
}
/*no CSS*/

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	background: url(../images/controls.png) -86px -11px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	background: url(../images/controls.png) -86px -44px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}
